<template>
  <div class="flex">
    <div class="flex w-full px-8 lg:px-20">
      <div class="flex-none text-sm w-6">
        <div
          @click="$emit('back')"
          class="
            cursor-pointer
            stroke-0
            text-grey3
            fill-current
            opacity-75
            hover:opacity-100
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
            />
          </svg>
        </div>
      </div>
      <div class="flex-grow text-base1 text-center text-lg font-semibold px-2">
        Great! You’ve picked this event:
      </div>
      <div class="flex-none w-6"></div>
    </div>
    <div class="w-full flex flex-col items-center px-0 lg:px-20">
      <div class="flex flex-col bg-base4 lg:p-2 mb-4 mt-8 lg:rounded-lg w-full">
        <event-panel
          class=""
          :event="selectedEvent"
          :highlight="selected"
          :meetricInfo="defaultMeetricInfo"
          :eventTime="formatEventTime"
          :guests="getGuests"
          :linkDisabled="true"
        >
        </event-panel>
      </div>
    </div>

    <div
      class="text-base1 text-center text-lg font-semibold my-4 px-8 lg:px-20"
    >
      Next, you’ll be able to add talking points in Meetric,
      <span v-show="!myselfOnlyAttendee">
        on your own or with the other participants,
      </span>
      so everyone’s prepared and the meeting can run smoothly!
    </div>

    <div class="flex px-8 lg:px-20">
      <m-button
        v-if="selectedEvent != 'none'"
        @click="startMeeting"
        class="mx-auto px-6 py-5 my-4"
        segmentName="Onboarding3-next"
        icon="➡️"
        :pending="buttonPending"
        >Next</m-button
      >
    </div>
    <template v-if="meetingInFuture">
      <div
        class="
          text-center
          mt-4
          mb-2
          font-medium
          text-grey1
          opacity-90
          px-8
          lg:px-20
        "
      >
        <span class="font-bold">Old habits die hard:</span> we can remind you to
        use Meetric right before this event starts.
      </div>
      <div class="mb-6 px-8 lg:px-20">
        <m-checkbox v-model="sendReminder" segmentName="Onboarding reminder"
          ><div class="ml-2 font-medium text-grey1 opacity-90">
            Yes, email me 15 minutes before
          </div></m-checkbox
        >
      </div>
    </template>

    <div class="text-center font-medium text-grey1 opacity-90 px-8 lg:px-20">
      Wanna be a meeting rockstar?
      <a-link
        segmentName="Discover pro"
        href="https://help.meetric.app/en/articles/5507341-meetric-pro"
        >Upgrade to Meetric Pro 💎</a-link
      >
    </div>
    <div
      v-if="colleaguesCount > 0"
      class="mt-3 font-medium text-grey1 opacity-90"
    >
      Did you know?
      <a-link segmentName="Discover pro" href="/company"
        >{{ colleaguesCount }} of your colleagues</a-link
      >
      have used Meetric.
    </div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import ALink from '@/components/UI/ALink';
import MCheckbox from '@/components/UI/MCheckbox';
import EventPanel from '@/views/Meetings/EventPanel.vue';
import { getMeetingAttendees } from '@/components/Utils';
import { setMeetingReminder, insertSampleNotes } from '@/api/Cherry';
import moment from 'moment';

export default {
  name: 'Page3StartNotes',
  components: {
    MButton,
    ALink,
    MCheckbox,
    EventPanel,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
    hasTitle: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    myEmail() {
      return this.$gAuth?.basicProfile?.getEmail() || '';
    },
    selectedEvent() {
      const event = this.events.find((e) => {
        return e.event.id == this.selected;
      });
      // format EventPanel expects
      return { e: event.event, cid: event.cid };
    },
    formatEventTime() {
      if (this.selectedEvent.e.start.dateTime) {
        const startTime = moment(this.selectedEvent.e.start.dateTime);
        const endTime = moment(this.selectedEvent.e.end.dateTime);
        return startTime.format('hh:mma') + ' - ' + endTime.format('hh:mma');
      }
      return '12:00am - 12:00am';
    },
    getGuests() {
      return getMeetingAttendees(this.selectedEvent.e);
    },
    myselfOnlyAttendee() {
      if (
        this.getGuests.length == 1 &&
        this.getGuests[0].email === this.myEmail
      )
        return true;

      return false;
    },
    eventTime() {
      if (this.selectedEvent.e.start) {
        if (this.selectedEvent.e.start.dateTime) {
          return this.selectedEvent.e.start.dateTime;
        } else {
          return this.selectedEvent.e.start.date;
        }
      }
      return '';
    },
    meetingInFuture() {
      // only more than 15 minutes in future
      return moment().add(15, 'minute').isBefore(this.eventTime);
    },
  },
  data() {
    return {
      sendReminder: true,
      colleaguesCount: 0,
      buttonPending: false,
      defaultMeetricInfo: {
        public: { has_notes: false, actions: {} },
        private: { has_notes: false, actions: {} },
      },
    };
  },
  mounted() {
    this.getColleaguesCount();
  },
  methods: {
    startMeeting: function () {
      if (this.hasTitle) {
        this.$emit('page', { index: 4 });
      } else {
        this.buttonPending = true;
        if (this.sendReminder && this.meetingInFuture) {
          const payload = {
            event_info: this.selectedEvent.e,
            type: 'welcome',
            offset: 15, // 15 minutes
          };
          setMeetingReminder(payload, this.selectedEvent.cid, this.selected);
        }
        insertSampleNotes(this.selected).then(() => {
          // at this point sample notes were created if meeting empty or noting were done
          // either way we want to redirect
          this.$router.replace({
            name: 'meeting',
            params: { id: this.selected, calendarId: this.selectedEvent.cid },
          });
          this.buttonPending = false;
        });
      }
    },
    getColleaguesCount: function () {
      this.colleaguesCount = this.$store.getters['colleagues'].count;
    },
  },
};
</script>
