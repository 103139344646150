<template>
  <div class="flex">
    <div class="flex w-full px-8 lg:px-20 mb-5">
      <div class="flex-none text-sm w-6">
        <div
          @click="$emit('page', 1)"
          class="
            cursor-pointer
            stroke-0
            text-grey3
            fill-current
            opacity-75
            hover:opacity-100
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
            />
          </svg>
        </div>
      </div>
      <div class="flex-grow text-base1 text-center text-lg font-semibold px-2">
        <template>
          Final step: you qualify for an onboarding call with Paul (Co-founder &
          CEO), to discover hidden features and get set up for success:
        </template>
      </div>
      <div class="flex-none w-6"></div>
    </div>

    <div class="mb-8">
      <a-link
        nolinkstyle
        segmentName="Integration preferences"
        href="https://calendly.com/paul-meetric/10-minute-chat-with-meetric-ceo"
        class="mx-auto mb-5 mt-5"
      >
        <m-button icon="📆"> Book 10min call with Paul</m-button>
      </a-link>
    </div>

    <div
      class="flex-grow text-base1 text-center text-lg font-semibold px-2 mb-5"
    >
      <template>
        Otherwise, click below to view your selected event in Meetric:
      </template>
    </div>
    <div class="flex">
      <m-button
        id="button_connected_next"
        @click="onNextClick"
        class="mx-auto px-6 py-5"
        segmentName="Onboarding1-next"
        :pending="buttonPending"
        icon="➡️"
        >View event</m-button
      >
    </div>
    <div class="mt-8 font-medium text-grey1 opacity-90">
      Wanna be a meeting rockstar?
      <a-link
        segmentName="Discover pro"
        href="https://help.meetric.app/en/articles/5507341-meetric-pro"
        >Upgrade to Meetric Pro 💎</a-link
      >
    </div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import ALink from '@/components/UI/ALink';
import { redirWhenEventExists } from '@/components/Utils';
import { setMeetingReminder, insertSampleNotes } from '@/api/Cherry';

export default {
  name: 'Page5Title',
  components: {
    MButton,
    ALink,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: false,
    },
    hasTitle: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    selectedEvent() {
      const event = this.events.find((e) => {
        return e.event.id == this.selected;
      });
      // format EventPanel expects
      if (event) return { e: event.event, cid: event.cid };

      return null;
    },
    calendarName() {
      const calendars = this.$store.getters['selectedCalendars'].map((u) => {
        if (u == 'primary') {
          return this.$gAuth.basicProfile.getEmail();
        }
        return decodeURIComponent(u);
      });
      return calendars.join(', ');
    },
    connectionCssVars() {
      return {
        '--distance': this.mouseDistance,
        '--halo-opacity': this.haloOpacity,
      };
    },
  },
  data() {
    return {
      signingIn: false,
      nextButton: null,
      showConnections: false,
      mouseDistance: 1,
      haloOpacity: 0,
      colleaguesCount: 0,
      buttonPending: false,
      welcomeEventId: '',
    };
  },
  methods: {
    getColleaguesCount: function () {
      this.colleaguesCount = this.$store.getters['colleagues'].count;
    },
    onNextClick() {
      if (this.welcomeEventId != this.selectedEvent) {
        this.buttonPending = true;
        if (this.sendReminder && this.meetingInFuture) {
          const payload = {
            event_info: this.selectedEvent.e,
            type: 'welcome',
            offset: 15, // 15 minutes
          };
          setMeetingReminder(payload, this.selectedEvent.cid, this.selected);
        }
        insertSampleNotes(this.selected).then(() => {
          // at this point sample notes were created if meeting empty or noting were done
          // either way we want to redirect
          this.$router.replace({
            name: 'meeting',
            params: { id: this.selected, calendarId: this.selectedEvent.cid },
          });
          this.buttonPending = false;
        });
      } else {
        // go to sample meeting directly
        this.buttonPending = true;
        const event = this.events.find(
          (e) => e.event.id === this.selectedEvent
        );
        if (event.meetricAdded) {
          // sample event was added by us -> it wasn't in initial fetch
          // it should exist in calendar but just in case
          redirWhenEventExists('primary', this.selectedEvent);
          return;
        }
        // sample event was in the initial fetch, go directly
        this.$router.replace({
          name: 'meeting',
          params: { id: this.selectedEvent, calendarId: 'primary' },
        });
      }
    },
  },
  mounted() {
    this.getColleaguesCount();
    this.welcomeEventId = this.$store.getters['welcomeEventId'];
  },
};
</script>
<style scoped>
.halo {
  fill: #ffdf92;
  stroke: #ffdf92;
  fill-opacity: var(--halo-opacity);
}
.halospikes {
  animation: spikesconnected 0.2s ease none infinite alternate;
  fill: none;
  stroke: #ffdf92;
  stroke-width: 1.365;
  stroke-linecap: round;
  stroke-linejoin: miter;
  stroke-opacity: 1;
  font-variation-settings: normal;
  opacity: 1;
  vector-effect: none;
  fill-opacity: 1;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stop-color: #000000;
  stop-opacity: 1;
}
.connections path {
  animation: connected 1s ease none infinite alternate;
}

.creeping path {
  stroke-dashoffset: var(--distance);
  stroke-dasharray: 1;
}

@keyframes connected {
  from {
    stroke-dasharray: 0.05;
    stroke-dashoffset: 1;
  }
  to {
    stroke-dasharray: 0.05;
    stroke-dashoffset: 0;
  }
}
@keyframes spikesconnected {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0.5;
  }
}
</style>
