<template>
  <div class="text-center px-8 lg:px-20">
    <div class="text-base1 font-semibold text-3xl">
      👋 Welcome {{ userName }}
    </div>
    <div class="mt-8 font-semibold text-base1 text-lg w-full">
      Meetric connects your <i>calendar</i> to your
      <i>meeting discussions</i> (agendas, notes, decisions, actions) - removing
      the admin and making collaboration a breeze!
    </div>

    <div class="h-72 flex">
      <svg
        class="mx-auto my-auto"
        width="200"
        height="200"
        viewBox="0 0 52.916665 52.916668"
        version="1.1"
        id="svg5"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
      >
        <sodipodi:namedview
          id="namedview7"
          pagecolor="#ffffff"
          bordercolor="#666666"
          borderopacity="1.0"
          showgrid="true"
          units="px"
          width="200px"
        >
        </sodipodi:namedview>
        <g
          id="layer5"
          class="halo"
          :style="showConnections ? '' : connectionCssVars"
        >
          <path
            style="
              font-variation-settings: normal;
              vector-effect: none;
              stroke: none;
              stroke-width: 0.249288;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-dashoffset: 0;
              stroke-opacity: 1;
              stop-color: #000000;
              stop-opacity: 1;
            "
            d="M 5.1961524,16 C 9.1232863,9.5752149 14.14319,6.7063392 21.172852,4.7443516 37.467557,0.19647874 26.281877,23.396725 36.609757,20.111862 42.867618,18.1215 51.740185,25.574774 47.201608,38.05586 42.692592,50.455656 9.3520093,43.909069 5.6018766,38.939102 1.9807287,34.140073 2.5980761,19.5 5.1961523,16"
            id="path19795"
            sodipodi:nodetypes="cssssc"
          />
        </g>
        <g id="layer6" class="halospikes" v-if="showConnections">
          <path
            pathLength="1"
            d="M 32.908966,7 C 34.656467,6.3749528 33.856894,4.3493834 35.507042,3.5"
            id="path20893"
            sodipodi:nodetypes="cc"
          />
          <path
            pathLength="1"
            d="m 42.435245,18.5 c 0.433792,-0.447781 1.701213,-3.927956 1.73205,-4"
            id="path22402"
          />
          <path
            pathLength="1"
            d="M 47.631397,21.5 C 48.753132,20.248845 50.76482,19.625344 51.961523,19"
            id="path22404"
          />
          <path
            pathLength="1"
            d="m 35.507041,15.5 c 0.407413,-1.645882 2.25467,-2.820643 3.464102,-4"
            id="path22406"
          />
          <path
            pathLength="1"
            d="m 26.846788,3.5 c 0.226277,-0.6976811 0,-1.294742 0,-2"
            id="path22408"
          />
          <path
            pathLength="1"
            d="M 18.186533,4.4999999 C 17.067511,3.9302647 16.305958,2.7917198 15.588457,2"
            id="path22410"
          />
          <path
            pathLength="1"
            d="M 11.25833,7.5 C 10.317116,6.9060496 9.6425101,4.7180065 9.5262794,4.4999999"
            id="path22412"
          />
          <path
            pathLength="1"
            d="M 1.7320508,17 C 1.2019723,17.508766 0.99755665,16.57594 0.8660254,16.5"
            id="path22418"
          />
          <path
            pathLength="1"
            d="M 1.7320508,25 C 1.2963262,25.466186 1.0398724,24.60037 0.8660254,24.5"
            id="path22420"
          />
          <path
            pathLength="1"
            d="m 2.5980762,29.499999 c -0.4216791,0.571608 -1.1560637,0 -1.7320508,0"
            id="path22424"
          />
          <path
            pathLength="1"
            d="m 2.5980762,34.499999 c 0.060197,0.759299 -0.6411979,0.370197 -0.8660254,0.499999"
            id="path22428"
          />
          <path
            pathLength="1"
            d="M 3.4641017,43.000001 C 3.2788312,43.277102 2.8867512,43.333333 2.5980762,43.5"
            id="path22430"
          />
          <path
            pathLength="1"
            d="M 9.5262794,43.5 C 9.1025947,44.273477 8.3715788,44.833333 7.7942286,45.499998"
            id="path22432"
          />
          <path d="M 15.588457,44.999999 14.722432,48.5" id="path22436" />
          pathLength="1"
          <path
            pathLength="1"
            d="m 21.650635,46.499999 c 0.312965,0.485166 0.57735,1.000001 0.866025,1.5"
            id="path22442"
          />
          <path
            pathLength="1"
            d="m 28.578839,46.499999 c -0.450279,0.491625 0,1.333333 0,2.000001"
            id="path22444"
          />
          <path
            pathLength="1"
            d="m 34.641017,46 c -0.04226,0.665327 0,1.333333 0,1.999999"
            id="path22446"
          />
          <path
            pathLength="1"
            d="m 39.837169,46 c 0.526936,0.235945 0.57735,1.000001 0.866024,1.5"
            id="path22448"
          />
          <path
            pathLength="1"
            d="m 45.033321,43.999999 c 0.870731,0.140012 1.1547,1.333333 1.73205,2.000001"
            id="path22450"
          />
          <path
            pathLength="1"
            d="M 49.363447,41.499998 C 49.548234,40.810788 50.034091,41.887195 50.229473,42"
            id="path22452"
          />
          <path
            pathLength="1"
            d="M 50.566332,36.500001 C 50.37747,36.580958 51.056973,36.783271 51.432356,37"
            id="path22454"
          />
          <path
            pathLength="1"
            d="m 50.566332,32.500001 c -0.247273,0.148946 0.669285,-0.405495 0.866024,-0.500002"
            id="path22456"
            sodipodi:nodetypes="cc"
          />
          <path
            pathLength="1"
            d="m 50.566332,26.5 c 0.244131,0.226959 0.57735,-0.333334 0.866024,-0.5"
            id="path22458"
          />
          <path
            pathLength="1"
            d="M 6.9282031,11 C 6.1046036,10.401146 5.4907269,9.340145 5.1961523,8.9999999"
            id="path22462"
          />
        </g>
        <g
          id="layer2"
          style="display: inline"
          :class="showConnections ? 'connections' : 'creeping'"
          :style="connectionCssVars"
        >
          <path
            pathLength="1"
            style="
              fill: none;
              stroke: #00a4bd;
              stroke-width: 0.565;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-opacity: 1;
            "
            d="m 38.10512,35 -3.464102,2.000002 6.928202,4 -4.330126,2.499999 -6.928203,-4"
            id="path3286"
            sodipodi:nodetypes="ccc"
          />
          <path
            pathLength="1"
            style="
              font-variation-settings: normal;
              vector-effect: none;
              fill: none;
              fill-opacity: 1;
              stroke: #00a4bd;
              stroke-width: 0.565;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-opacity: 1;
              stop-color: #000000;
            "
            d="m 12.990383,34.500001 8.660254,4.999998 4.330127,-2.499997 4.330128,2.499997"
            id="path19118"
          />
        </g>
        <g id="layer1" style="display: inline">
          <path
            style="
              font-variation-settings: normal;
              opacity: 1;
              vector-effect: none;
              fill: #33475b;
              fill-opacity: 1;
              stroke: none;
              stroke-width: 0.264583px;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-dashoffset: 0;
              stroke-opacity: 1;
              stop-color: #000000;
              stop-opacity: 1;
            "
            d="m 35.843471,25.207998 1.858706,-1.04053 0.495019,0.541735 -2.113743,1.003421"
            id="path15499"
          />
          <path
            style="
              font-variation-settings: normal;
              opacity: 1;
              vector-effect: none;
              fill: #33475b;
              fill-opacity: 1;
              stroke: none;
              stroke-width: 0.264583px;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-dashoffset: 0;
              stroke-opacity: 1;
              stop-color: #000000;
              stop-opacity: 1;
            "
            d="m 41.94959,35.827435 1.873872,-1.057559 -0.773565,0.02156 -1.115129,0.611209 z"
            id="path14978"
          />
          <path
            style="
              fill: #33475b;
              fill-opacity: 1;
              stroke: none;
              stroke-width: 0.264583px;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-opacity: 1;
            "
            d="m 40.703194,24.500003 0.04562,4.998168 4.284509,2.50183 m 0,0 c 0,2.761423 -1.938664,3.880711 -4.330127,2.5 -2.391463,-1.380711 -4.330127,-4.738575 -4.330127,-7.499998 0,-2.761423 1.938664,-3.880711 4.330127,-2.5"
            id="path6474-1"
            sodipodi:nodetypes="ccccssc"
          />
          <path
            style="
              fill: #00a4bd;
              fill-opacity: 1;
              stroke: none;
              stroke-width: 0.264583px;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-opacity: 1;
            "
            d="m 38.925525,25.501833 0.04562,4.998168 4.284509,2.50183 m 0,0 c 0,2.761423 -1.938664,3.880711 -4.330127,2.5 C 36.534064,34.12112 34.5954,30.763256 34.5954,28.001833 c 0,-2.761423 1.938664,-3.880711 4.330127,-2.5"
            id="path6474"
            sodipodi:nodetypes="ccccssc"
          />
          <path
            style="
              font-variation-settings: normal;
              opacity: 1;
              vector-effect: none;
              fill: none;
              fill-opacity: 1;
              stroke: #000000;
              stroke-width: 0.065;
              stroke-linecap: round;
              stroke-linejoin: miter;
              stroke-miterlimit: 0.8;
              stroke-dasharray: none;
              stroke-dashoffset: 0;
              stroke-opacity: 1;
              stop-color: #000000;
              stop-opacity: 1;
            "
            d="m 38.971143,30.5 1.732051,-1.000001 v 0 0 0"
            id="path8563"
          />
          <path
            style="
              font-variation-settings: normal;
              opacity: 1;
              vector-effect: none;
              fill: none;
              fill-opacity: 1;
              stroke: #000000;
              stroke-width: 0.065;
              stroke-linecap: round;
              stroke-linejoin: miter;
              stroke-miterlimit: 0.8;
              stroke-dasharray: none;
              stroke-dashoffset: 0;
              stroke-opacity: 1;
              stop-color: #000000;
              stop-opacity: 1;
            "
            d="m 43.30127,33 1.732051,-1.000001"
            id="path8565"
          />
          <path
            style="
              fill: none;
              stroke: #000000;
              stroke-width: 0.065;
              stroke-linecap: round;
              stroke-linejoin: miter;
              stroke-miterlimit: 0.8;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 38.925525,25.501833 1.777671,-1.00183"
            id="path8567"
            sodipodi:nodetypes="cc"
          />
        </g>
        <g id="layer4">
          <path
            style="
              fill: #f5f8fa;
              fill-opacity: 1;
              stroke: #33475b;
              stroke-width: 0.264583px;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-opacity: 1;
            "
            d="m 27.712814,7.0000014 -1.732051,-1 L 6.9282052,17.000001 v 19 l 1.73205,1"
            id="path1016"
          />
          <path
            style="
              font-variation-settings: normal;
              opacity: 1;
              vector-effect: none;
              fill: #f5f8fa;
              fill-opacity: 1;
              stroke: #33475b;
              stroke-width: 0.264583px;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-dashoffset: 0;
              stroke-opacity: 1;
              stop-color: #000000;
              stop-opacity: 1;
            "
            d="m 8.6602562,18.000001 -10e-7,19 19.0525588,-11 V 7.0000014 Z"
            id="path1014"
            sodipodi:nodetypes="ccccc"
          />
          <path
            style="
              font-variation-settings: normal;
              opacity: 1;
              vector-effect: none;
              fill: #33475b;
              fill-opacity: 1;
              stroke: #33475b;
              stroke-width: 0.264583;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-dashoffset: 0;
              stroke-opacity: 1;
              stop-color: #000000;
              stop-opacity: 1;
            "
            d="m 27.712814,10.000001 -6.062178,3.5 v 0 l -12.9903808,7.5 v -3 L 27.712814,7.0000014 v 2.9999996 l -19.0525588,11"
            id="path1018"
            sodipodi:nodetypes="cccc"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 14.722433,18.500001 v 2 l 1.732051,-1 v -2 z"
            id="path1113"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 17.320509,17.000001 v 2 l 1.732051,-1 v -2 z"
            id="path1115"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 19.918585,15.500001 v 2 l 1.732051,-1 v -2 z"
            id="path1117"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 22.516662,14.000001 v 2 l 1.732051,-1 v -2 z"
            id="path1119"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 25.114738,12.500001 v 2 l 1.732051,-1 v -2 z"
            id="path1121"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 9.5262812,24.500001 v 2 l 1.7320508,-1 v -2 z"
            id="path1826"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 12.124357,23.000001 v 2 l 1.732051,-1 v -2 z"
            id="path1828"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 14.722433,21.500001 v 2 l 1.732051,-1 v -2 z"
            id="path1830"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 17.320509,20.000001 v 2 l 1.732051,-1 v -2 z"
            id="path1832"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 19.918585,18.500001 v 2 l 1.732051,-1 v -2 z"
            id="path1834"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 22.516662,17.000001 v 2 l 1.732051,-1 v -2 z"
            id="path1836"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 25.114738,15.500001 v 2 l 1.732051,-1 v -2 z"
            id="path1838"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 9.5262812,27.500001 v 2 l 1.7320508,-1 v -2 z"
            id="path1996"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 12.124357,26.000001 v 2 l 1.732051,-1 v -2 z"
            id="path1998"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 14.722433,24.500001 v 2 l 1.732051,-1 v -2 z"
            id="path2000"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 17.320509,23.000001 v 2 l 1.732051,-1 v -2 z"
            id="path2002"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 19.918585,21.500001 v 2 l 1.732051,-1 v -2 z"
            id="path2004"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 22.516662,20.000001 v 2 l 1.732051,-1 v -2 z"
            id="path2006"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 25.114738,18.500001 v 2 l 1.732051,-1 v -2 z"
            id="path2008"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 9.5262812,30.500001 v 2 l 1.7320508,-1 v -2 z"
            id="path2010"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 12.124357,29.000001 v 2 l 1.732051,-1 v -2 z"
            id="path2012"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 14.722433,27.500001 v 2 l 1.732051,-1 v -2 z"
            id="path2014"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 17.320509,26.000001 v 2 l 1.732051,-1 v -2 z"
            id="path2016"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 19.918585,24.500001 v 2 l 1.732051,-1 v -2 z"
            id="path2018"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 22.516662,23.000001 v 2 l 1.732051,-1 v -2 z"
            id="path2020"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 25.114738,21.500001 v 2 l 1.732051,-1 v -2 z"
            id="path2022"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 9.5262812,33.500001 v 2 l 1.7320508,-1 v -2 z"
            id="path2024"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 12.124357,32.000001 v 2 l 1.732051,-1 v -2 z"
            id="path2026"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 14.722433,30.500001 v 2 l 1.732051,-1 v -2 z"
            id="path2028"
          />
          <path
            style="
              fill: #b5b5b5;
              fill-opacity: 1;
              stroke: #b5b5b0;
              stroke-width: 0.065;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            d="m 17.320509,29.000001 v 2 l 1.732051,-1 v -2 z"
            id="path2030"
          />
          <path
            style="
              font-variation-settings: normal;
              opacity: 1;
              vector-effect: none;
              fill: none;
              fill-opacity: 1;
              stroke: #33475b;
              stroke-width: 0.264583;
              stroke-linecap: butt;
              stroke-linejoin: miter;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-dashoffset: 0;
              stroke-opacity: 1;
              stop-color: #000000;
              stop-opacity: 1;
            "
            d="m 6.9282052,17.000001 1.73205,1"
            id="path13644"
          />
        </g>
      </svg>
    </div>
    <div class="flex">
      <m-button
        id="button_connected_next"
        @click="$emit('next')"
        @mouseover.native="lightup"
        @mouseout.native="lightsoff"
        class="mx-auto px-6 py-5"
        segmentName="Onboarding1-next"
        icon="➡️"
        >Next</m-button
      >
    </div>
    <div class="mt-16 font-medium text-grey1 opacity-90">
      Wanna be a meeting rockstar?
      <a-link
        segmentName="Discover pro"
        href="https://help.meetric.app/en/articles/5507341-meetric-pro"
        >Discover Meetric Pro 💎</a-link
      >
    </div>
    <div v-if="colleaguesCount > 0" class="mt-3 font-medium text-grey1 opacity-90">
      Did you know?
      <a-link
        segmentName="Discover pro"
        href="/company"
        >{{ colleaguesCount }} of your colleagues</a-link
      >
      have used Meetric.
    </div>

  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import ALink from '@/components/UI/ALink';

export default {
  name: 'Page1Connected',
  components: {
    MButton,
    ALink,
  },
  computed: {
    userName() {
      if (this.$gAuth.basicProfile.getGivenName())
        return this.$gAuth.basicProfile.getGivenName();
      else if (this.$gAuth.basicProfile.getName())
        return this.$gAuth.basicProfile.getName();
      else return this.$gAuth.basicProfile.getEmail();
    },
    calendarName() {
      const calendars = this.$store.getters['selectedCalendars'].map((u) => {
        if (u == 'primary') {
          return this.$gAuth.basicProfile.getEmail();
        }
        return decodeURIComponent(u);
      });
      return calendars.join(', ');
    },
    connectionCssVars() {
      return {
        '--distance': this.mouseDistance,
        '--halo-opacity': this.haloOpacity,
      };
    },
  },
  data() {
    return {
      signingIn: false,
      nextButton: null,
      showConnections: false,
      mouseDistance: 1,
      haloOpacity: 0,
      colleaguesCount: 0,
    };
  },
  methods: {
    calculateDistance: function (event) {
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      const rect = this.nextButton.getBoundingClientRect();
      const centreX = (rect.left + rect.right) / 2;
      const centreY = (rect.top + rect.bottom) / 2;
      const distance = Math.floor(
        Math.sqrt(Math.pow(mouseX - centreX, 2) + Math.pow(mouseY - centreY, 2))
      );
      this.mouseDistance = distance > 400 ? 1 : distance / 400;
      this.haloOpacity = distance > 100 ? 0 : 1 - distance / 100;
    },
    lightup: function () {
      this.showConnections = true;
    },
    lightsoff: function () {
      this.showConnections = false;
    },
    getColleaguesCount: function () {
      this.colleaguesCount = this.$store.getters['colleagues'].count;
    },
  },
  mounted() {
    this.nextButton = document.getElementById('button_connected_next');
    window.addEventListener('mousemove', this.calculateDistance);
    this.getColleaguesCount();
  },
  destroyed: function () {
    window.removeEventListener('mousemove', this.calculateDistance);
  },
};
</script>
<style scoped>
.halo {
  fill: #ffdf92;
  stroke: #ffdf92;
  fill-opacity: var(--halo-opacity);
}
.halospikes {
  animation: spikesconnected 0.2s ease none infinite alternate;
  fill: none;
  stroke: #ffdf92;
  stroke-width: 1.365;
  stroke-linecap: round;
  stroke-linejoin: miter;
  stroke-opacity: 1;
  font-variation-settings: normal;
  opacity: 1;
  vector-effect: none;
  fill-opacity: 1;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stop-color: #000000;
  stop-opacity: 1;
}
.connections path {
  animation: connected 1s ease none infinite alternate;
}

.creeping path {
  stroke-dashoffset: var(--distance);
  stroke-dasharray: 1;
}

@keyframes connected {
  from {
    stroke-dasharray: 0.05;
    stroke-dashoffset: 1;
  }
  to {
    stroke-dasharray: 0.05;
    stroke-dashoffset: 0;
  }
}
@keyframes spikesconnected {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 0.5;
  }
}
</style>
