<template>
  <transition name="slide-transition" mode="out-in" appear>
    <component
      id="onboarding-wrapper"
      class="
        min-h-screen
        lg:min-h-0
        py-16
        flex flex-col
        items-center
        lg:mx-auto
        bg-white
        dark:bg-base5
        shadow-lg
        -mt-10
        lg:mt-10
        w-full
        lg:rounded-xl
      "
      @update="updateEvents()"
      @page="openPage($event)"
      @back="prevPage()"
      @next="nextPage($event)"
      :events="events"
      :selected="selectedMeeting"
      v-bind:is="pages[pageIndex]"
      :hasTitle="hasTitle"
    ></component>
  </transition>
</template>

<script>
import Page1Connected from './Page1-Connected';
import Page2SelectMeeting from './Page2-SelectMeeting.vue';
import Page3StartNotes from './Page3-StartNotes.vue';
import Page4AddCalendar from './Page4-AddCalendar.vue';
import Page5Title from './Page5-Title.vue';
import { getAllCalendarEvents } from '@/api/Google';
import moment from 'moment';

const rupertUrl = process.env.VUE_APP_RUPERT2000_API_URL;
const titles = [
  'Director',
  'CEO',
  'Head',
  'Senior',
  'VP',
  'Lead',
  'Chief',
  'CPO',
  'CFO',
  'CTO',
  'CMO',
  'CRO',
  'Vice',
  'President',
];

export default {
  name: 'OnboardingTour',
  components: {
    Page1Connected,
    Page2SelectMeeting,
    Page3StartNotes,
    Page4AddCalendar,
    Page5Title,
  },
  props: {},
  data() {
    return {
      pageIndex: 0,
      pages: [
        'Page1Connected',
        'Page2SelectMeeting',
        'Page3StartNotes',
        'Page4AddCalendar',
        'Page5Title',
      ],
      events: [],
      selectedMeeting: '',
      welcomeEventId: '',
      hasTitle: false,
    };
  },
  computed: {},
  mounted() {
    this.updateEvents();
    this.getTitleByRupert();
  },
  beforeDestroy() {},
  methods: {
    nextPage: function (id) {
      this.selectedMeeting = id;
      this.pageIndex++;
    },
    prevPage: function () {
      window.meetric.track('Onboarding - back');
      this.pageIndex--;
    },
    openPage: function (event) {
      if (event.id) {
        this.selectedMeeting = event.id;
      }
      this.pageIndex = event.index;
    },
    updateEvents() {
      const welcomeEventId = this.$store?.getters['welcomeEventId'];
      const calendars = this.$store.getters['selectedCalendars'];
      const gParams = {
        timeMax: moment(new Date()).add(7, 'days').endOf('day').format(),
        timeMin: moment(new Date()).subtract(7, 'days').startOf('day').format(),
      };
      getAllCalendarEvents(calendars, gParams).then((resp) => {
        this.events = this.addSampleIfNotExists(resp, welcomeEventId);

        window.meetric.track('Onboarding - calendars', {
          numEvents: this.events.length,
        });
      });
    },
    addSampleIfNotExists(events, welcomeEventId) {
      let sampleMeeting = events.find((e) => e.event.id === welcomeEventId);
      if (!welcomeEventId || sampleMeeting) return [...events];

      return [...events, this.sampleMeeting(welcomeEventId)];
    },
    // Generate sample meeting in local events so user can select it
    sampleMeeting(welcomeEventId) {
      if (!welcomeEventId) return {};

      return {
        cid: 'primary',
        event: {
          id: welcomeEventId,
          summary: '👋 Meetric welcome meeting',
          start: { dateTime: moment(new Date()).format() },
          end: { dateTime: moment(new Date()).add(10, 'minutes').format() },
          meetricAdded: true,
        },
      };
    },
    getTitleByRupert() {
      let email = this.$gAuth.basicProfile.getEmail();
      let name = this.$gAuth.basicProfile.getName();
      fetch(`${rupertUrl}?name=${name}&email=${email}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }).then((resp) => {
        if (resp.status == 200) {
          const jsonResponse = resp
            .clone()
            .json()
            .then((r) => {
              let title = r.Title;
              if (titles.filter((t) => title.includes(t)).length > 0) {
                this.hasTitle = true;
              }
            });
          return jsonResponse;
        }
      });
    },
  },
};
</script>
<style scoped>
@screen lg {
  #onboarding-wrapper {
    width: 800px;
  }
}

.slide-transition-enter-active,
.slide-transition-leave-active {
  transition: opacity 3s ease;
}
.slide-transition-enter,
.slide-transition-leave-to {
  opacity: 0;
}
</style>
