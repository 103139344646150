<template>
  <div class="flex">
    <div class="flex w-full px-8 lg:px-20">
      <div class="flex-none text-sm w-6">
        <div
          @click="$emit('back')"
          class="
            cursor-pointer
            stroke-0
            text-grey3
            fill-current
            opacity-75
            hover:opacity-100
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M7.828 11H20v2H7.828l5.364 5.364-1.414 1.414L4 12l7.778-7.778 1.414 1.414z"
            />
          </svg>
        </div>
      </div>
      <div class="flex-grow text-base1 text-center text-lg font-semibold px-2">
        <template v-if="hasOtherMeetingsToday">
          First, pick the event you want to try Meetric in: </template
        ><template v-else>
          We didn’t find events in your calendar for today...so we’ve picked for
          you a fake meeting to try Meetric in (otherwise pick an event on a
          different day)
        </template>
      </div>

      <div class="flex-none w-6"></div>
    </div>

    <div class="px-0 lg:px-20 w-full flex flex-col items-center">
      <div
        class="
          flex flex-col
          overflow-y-auto
          bg-base4
          lg:p-2
          mb-4
          mt-8
          lg:rounded-lg
          w-full
        "
      >
        <div
          class="
            mx-auto
            flex
            text-center
            items-center
            text-grey1 text-sm
            mb-4
            pt-2
          "
        >
          <a-link
            v-if="showLeft"
            button-style="btn-tertiary"
            @click.native="changeDate(-1)"
            class="mx-2 cursor-pointer"
            nolinkstyle
            segmentName="Onboarding2-backday"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class=""
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="#00a4bd"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <polyline points="15 6 9 12 15 18" />
            </svg>
          </a-link>

          <div class="w-24 text-lg font-semibold">
            {{ formattedSelectedDate }}
          </div>

          <a-link
            v-if="showRight"
            button-style="btn-tertiary"
            @click.native="changeDate(+1)"
            class="mx-2 cursor-pointer"
            nolinkstyle
            segmentName="Onboarding2-forwardday"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class=""
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="#00a4bd"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <polyline points="9 6 15 12 9 18" />
            </svg>
          </a-link>
        </div>
        <div v-if="!dayEvents" class="text-center my-4 text-grey2 text-sm">
          <div>No events on this day</div>
        </div>
        <div
          @click.stop="selectEvent(e.e.id)"
          class="cursor-pointer"
          v-for="e in dayEvents"
          :key="e.e.id"
        >
          <div class="flex">
            <!--
        :class="e.e.id == selectedEvent ? ' border border-base2' : 'text-grey1'"
          <div class="flex w-4 items-center mx-4">
            <m-radio
              disabled
              :value="e.e.id"
              :filterName="selectedEvent"
            ></m-radio>
          </div>
          -->
            <event-item
              linkDisabled
              :highlightCurrentTime="false"
              :highlight="selectedEvent"
              :event="e"
              :meetricInfo="mInfo"
              :class="{ 'mb-4': dayEvents && dayEvents.length > 1 }"
            ></event-item>
            <!--
          <div class="flex-grow">
            <div>
              {{ e.e.summary }}
            </div>
            <div class="text-sm">
              {{ formatEventTime(e.e) }}
            </div>
          </div>
          --></div>
        </div>
      </div>
    </div>

    <div class="flex px-8 lg:px-20">
      <m-button
        v-if="selectedEvent != 'none'"
        @click="onNextClick"
        :pending="nextButtonLoading"
        class="mx-auto px-6 py-5 my-4"
        segmentName="Onboarding2-next"
        icon="➡️"
        >Next</m-button
      >
    </div>
    <div
      class="
        text-center
        mt-4
        mb-3
        font-medium
        text-grey1
        opacity-90
        px-8
        lg:px-20
      "
    >
      Not many events displayed?
      <span
        class="
          mx-auto
          underline
          cursor-pointer
          text-grey1
          opacity-75
          hover:opacity-100
        "
        @click="$emit('page', 3)"
        >Add additional calendars</span
      >
      or
      <router-link
        :to="{ name: 'signout' }"
        class="
          mx-auto
          underline
          cursor-pointer
          text-grey1
          opacity-75
          hover:opacity-100
        "
      >
        sign in with another account
      </router-link>
    </div>

    <div class="text-center font-medium text-grey1 opacity-90 px-8 lg:px-20">
      Wanna be a meeting rockstar?
      <a-link
        segmentName="Discover pro"
        href="https://help.meetric.app/en/articles/5507341-meetric-pro"
        >Discover Meetric Pro 💎</a-link
      >
    </div>
    <div
      v-if="colleaguesCount > 0"
      class="mt-3 font-medium text-grey1 opacity-90"
    >
      Did you know?
      <a-link segmentName="Discover pro" href="/company"
        >{{ colleaguesCount }} of your colleagues</a-link
      >
      have used Meetric.
    </div>
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import ALink from '@/components/UI/ALink';
//import MCard from '@/components/UI/MCard';
//import MRadio from '@/components/UI/MRadio';
import EventItem from '@/views/Meetings/EventItem.vue';
import moment from 'moment';
import { getDatetimeFromEvent, sortObj } from '@/components/Utils';
import { redirWhenEventExists } from '@/components/Utils';

export default {
  name: 'Page2SelectMeeting',
  components: {
    MButton,
    ALink,
    //MCard,
    //MRadio,
    EventItem,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    hasTitle: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    calendarName() {
      return this.$gAuth.basicProfile.getEmail();
    },
    groupedEvents() {
      const groups = this.events.reduce((obj, item) => {
        const key = item.event.start.dateTime
          ? moment(item.event.start.dateTime).format('YYYY-MM-DD')
          : moment(item.event.start.date).format('YYYY-MM-DD');

        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = [];
        }

        obj[key].push({
          e: item.event,
          //cid: item.cid,
        });

        // Return the object to the next item in the loop
        return obj;
      }, {});
      const sortedGroups = sortObj(groups);
      Object.keys(sortedGroups).forEach((key) => {
        sortedGroups[key] = sortedGroups[key].sort(compareEvents);
      });

      return sortedGroups;
    },
    dayEvents() {
      if (this.selectedDate in this.groupedEvents) {
        return this.groupedEvents[this.selectedDate];
      }
      return false;
    },
    hasOtherMeetingsToday() {
      const today = moment().format('YYYY-MM-DD');
      const meetings = this.groupedEvents[today];
      // there should always be SAMPLE meeting
      return (meetings?.length || 0) > 1;
    },
    formattedSelectedDate() {
      return moment(this.selectedDate).format('ddd D MMM');
    },
  },
  data() {
    return {
      selectedDate: moment().format('YYYY-MM-DD'),
      selectedEvent: 'none',
      welcomeEventId: '',
      showLeft: true,
      showRight: true,
      daysOffset: 0,
      colleaguesCount: 0,
      nextButtonLoading: false,
      mInfo: {
        private: {
          has_notes: false,
          actions: {
            total: 0,
            complete: 0,
            incomplete: 0,
          },
        },
        public: {
          has_notes: false,
          actions: { total: 0, complete: 0, incomplete: 0 },
        },
      },
    };
  },
  mounted() {
    this.welcomeEventId = this.$store.getters['welcomeEventId'];
    this.selectedEvent = this.welcomeEventId;
    this.getColleaguesCount();
    this.selectedDate = moment().format('YYYY-MM-DD');
  },
  methods: {
    onNextClick() {
      if (this.hasTitle) {
        if (this.welcomeEventId != this.selectedEvent) {
          this.$emit('next', this.selectedEvent);
          return;
        }
        this.$emit('page', { index: 4, id: this.selectedEvent });
        return;
      } else {
        if (this.welcomeEventId != this.selectedEvent) {
          this.$emit('next', this.selectedEvent);
          return;
        }
        // go to sample meeting directly
        this.nextButtonLoading = true;
        const event = this.events.find(
          (e) => e.event.id === this.selectedEvent
        );
        if (event.meetricAdded) {
          // sample event was added by us -> it wasn't in initial fetch
          // it should exist in calendar but just in case
          redirWhenEventExists('primary', this.selectedEvent);
          return;
        }
        // sample event was in the initial fetch, go directly
        this.$router.replace({
          name: 'meeting',
          params: { id: this.selectedEvent, calendarId: 'primary' },
        });
      }
    },
    getColleaguesCount: function () {
      this.colleaguesCount = this.$store.getters['colleagues'].count;
    },
    changeDate: function (numDays) {
      this.daysOffset += numDays;
      if (this.daysOffset > 7) {
        this.showRight = false;
      } else if (this.daysOffset < -7) {
        this.showLeft = false;
      } else {
        this.showLeft = true;
        this.showRight = true;
      }
      this.selectedDate = moment(this.selectedDate)
        .add(numDays, 'days')
        .format('YYYY-MM-DD');
    },
    formatEventTime: function (e) {
      if (e.start.dateTime) {
        const startTime = moment(e.start.dateTime);
        const endTime = moment(e.end.dateTime);
        return startTime.format('hh:mma') + ' - ' + endTime.format('hh:mma');
      }
      return '12:00am - 12:00am';
    },
    selectEvent: function (id) {
      this.selectedEvent = id;
      window.meetric.track('Onboarding2 - select event');
    },
  },
};
function compareEvents(a, b) {
  if (getDatetimeFromEvent(a.e) < getDatetimeFromEvent(b.e)) {
    return -1;
  }
  if (getDatetimeFromEvent(a.e) > getDatetimeFromEvent(b.e)) {
    return 1;
  }
  return 0;
}
</script>
